<template>
  <div style="height: 600px" ref="postOfficeOrdersDailyChart" id="post-office-orders-daily-chart"></div>
</template>

<script>
import _ from 'lodash';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from 'echarts/components';
import {
  LineChart,
} from 'echarts/charts';
import {
  CanvasRenderer,
} from 'echarts/renderers';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
]);

export default {
  name: 'PostOfficeOrdersDailyChart',
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  mounted() {
    this.echartsInstance = echarts.init(this.$refs.postOfficeOrdersDailyChart);
    this.$api.office.get({ status: -1 }).then(({ data: response }) => {
      this.postOfficeList = [...response.data];
      this.render();
    });
  },
  data() {
    return {
      echartsInstance: null,
      postOfficeList: [],
    };
  },
  watch: {
    data: {
      handler: 'render',
      deep: true,
    },
  },
  methods: {
    render() {
      const dates = _.map(this.data, 'date');
      const dataKey = _.keyBy(this.data, 'date');
      const series = [];

      this.postOfficeList.forEach((po) => {
        const ordersData = [];
        dates.forEach((date) => {
          if (dataKey[date].post_office_orders[po.id]) {
            ordersData.push(dataKey[date].post_office_orders[po.id]);
          } else {
            ordersData.push(0);
          }
        });
        if (_.sum(ordersData)) {
          series.push({
            name: po.name,
            type: 'line',
            // stack: '訂單數',
            data: ordersData,
          });
        }
      });

      const option = {
        title: {
          text: '門店訂單數每日統計',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: _.map(series, 'name'),
          top: '10%',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: dates,
        },
        yAxis: {
          type: 'value',
        },
        series,
      };

      this.echartsInstance.setOption(option, {
        notMerge: true,
      });
    },
  },
};
</script>
