<template>
  <div style="height: 400px" ref="receiverDistrictsChart" id="receiver-district-chart"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import {
  PieChart,
} from 'echarts/charts';
import {
  CanvasRenderer,
} from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
]);

export default {
  name: 'RecevierDistrictsChart',
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  mounted() {
    const chart = echarts.init(this.$refs.receiverDistrictsChart);
    const option = {
      title: {
        text: '收件地區分佈',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: '訂單',
          type: 'pie',
          radius: '50%',
          data: [...this.data],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };
    chart.setOption(option);
  },
  computed: {},
};
</script>
