<template>
  <div style="height: 400px" ref="postOfficeOrdersChart" id="post-office-orders-chart"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import {
  PieChart,
} from 'echarts/charts';
import {
  CanvasRenderer,
} from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
]);

export default {
  name: 'PostOfficeOrdersChart',
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  mounted() {
    const data = [];
    this.data.forEach((po) => {
      if (po.orders.total) {
        data.push({
          name: po.name,
          value: po.orders.total,
        });
      }
    });

    const chart = echarts.init(this.$refs.postOfficeOrdersChart);
    const option = {
      title: {
        text: '門店訂單分佈',
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
      },
      legend: {
        orient: 'vertical',
        left: 'left',
      },
      series: [
        {
          name: '訂單',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data,
        },
      ],
    };

    chart.setOption(option);
  },
};
</script>
