<template>
  <ContentWrapper>
    <div class="content-heading">
      <div>Dashboard</div>
    </div>
    <div class="row statistics-row" v-if="realTimeStatistics">
      <div class="col-xl-2 col-md-6">
        <div class="card flex-row align-items-center align-items-stretch border-0">
          <div class="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
            <i class="fas fa-user fa-3x"></i>
          </div>
          <div class="col-8 py-3 bg-purple rounded-right">
            <div class="row">
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/users">
                    {{ realTimeStatistics.users.all }}
                  </router-link>
                </div>
                <div class="text-uppercase">會員</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">{{ realTimeStatistics.users.today }}</div>
                <div class="text-uppercase">今日</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-12">
        <div class="card flex-row align-items-center align-items-stretch border-0">
          <div class="col-2 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
            <i class="fas fa-user fa-3x"></i>
          </div>
          <div class="col-10 py-3 bg-primary rounded-right">
            <div class="row">
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/all">
                    {{ realTimeStatistics.orders.total || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">訂單</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/all">
                    {{ realTimeStatistics.orders.today || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">今日</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/unpaid">
                    {{ realTimeStatistics.orders.status[1] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">未支付</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/paid">
                    {{ realTimeStatistics.orders.status[2] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">已支付</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/pickup">
                    {{ realTimeStatistics.orders.status[3] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">已攬件</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/printed">
                    {{ realTimeStatistics.orders.status[4] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">運輸中</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/completed">
                    {{ realTimeStatistics.orders.status[5] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">已完成</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/orders/issue">
                    {{ realTimeStatistics.orders.issues || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">問題單</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6">
        <div class="card flex-row align-items-center align-items-stretch border-0">
          <div class="col-2 d-flex align-items-center bg-green-dark justify-content-center rounded-left">
            <i class="fas fa-user fa-3x"></i>
          </div>
          <div class="col-10 py-3 bg-green rounded-right">
            <div class="row">
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/pickup-orders/paid">
                    {{ realTimeStatistics.pickup_orders_status[1] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">已支付</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/pickup-orders/picking">
                    {{ realTimeStatistics.pickup_orders_status[2] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">取件中</div>
              </div>
              <div class="col text-center">
                <div class="h2 mt-0">
                  <router-link to="/pickup-orders/picked">
                    {{ realTimeStatistics.pickup_orders_status[3] || 0 }}
                  </router-link>
                </div>
                <div class="text-uppercase">已取件</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="currentUser && currentUser.all_privileges && realTimeStatistics">
      <div class="col">
        <div class="card card-default p-3">
          <!-- 收件地區分佈圖 -->
          <recevier-districts-chart :data="realTimeStatistics.receiver_districts" />
        </div>
      </div>
      <div class="col">
        <div class="card card-default p-3">
          <!-- 門店訂單分佈圖 -->
          <post-office-orders-chart :data="realTimeStatistics.post_offices_statistics" />
        </div>
      </div>
    </div>

    <div class="card card-default" v-if="currentUser && currentUser.all_privileges && dailyStatistics">
      <div class="card-wrapper">
        <div class="p-5">
          <el-date-picker
            v-model="dailyStatisticsDates"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            value-format="yyyy-MM-dd"
          />
          <el-button type="primary" class="ml-2" @click="getDailyStatistics">確定</el-button>
        </div>

        <div class="card-body">
          <!-- 新增用戶線狀圖 -->
          <users-daily-chart :data="dailyStatistics" />
          <!-- 新增訂單線狀圖 -->
          <orders-daily-chart :data="dailyStatistics" />
          <!-- 門店訂單數每日統計 -->
          <post-office-orders-daily-chart :data="dailyStatistics" />
        </div>
      </div>
    </div>

    <div class="card card-default" v-if="realTimeStatistics && parcelModels">
      <div class="card-header">
        <div class="card-title">門店統計</div>
      </div>
      <div class="card-wrapper">
        <div class="card-body">
          <table class="statistics-table table table-striped table-bordered table-hover">
            <thead>
              <tr>
                <th rowspan="2" width="20%">門店名稱</th>
                <th class="text-center" colspan="2">訂單統計</th>
                <th class="text-center" :colspan="parcelModelsType1.length">郵包統計 (已使用/未使用)</th>
              </tr>
              <tr>
                <th>總數</th>
                <th>今日</th>
                <th v-for="pm in parcelModelsType1" :key="pm.id">{{ pm.name }}</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="po in realTimeStatistics.post_offices_statistics"
              >
                <tr
                  :key="po.id"
                  v-if="hasPostOfficePermission(po.id)"
                >
                  <td>{{ po.name }}</td>
                  <td>{{ po.orders.total }}</td>
                  <td>{{ po.orders.today }}</td>
                  <td v-for="pm in parcelModelsType1" :key="pm.id">
                    <span>{{ po.parcels.used[pm.id] || 0 }}</span>
                    /
                    <span :class="{ 'text-danger': (po.parcels.unused[pm.id] || 0) < 20 }">
                    {{ po.parcels.unused[pm.id] || 0 }}
                  </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="card card-default" v-if="realTimeStatistics">
      <div class="card-header">
        <div class="card-title">用戶統計</div>
      </div>
      <div class="card-wrapper">
        <div class="card-body">
          <table class="statistics-table table table-striped table-bordered table-hover">
            <thead>
            <tr>
              <th>用戶ID</th>
              <th>用戶名</th>
              <th>郵箱</th>
              <th>訂單統計</th>
            </tr>
            </thead>
            <tbody>
            <template
                v-for="(po, key) in realTimeStatistics.user_order_count"
            >
              <tr
                  :key="key"
              >
                <td>{{ po.user_id }}</td>
                <td>
                  <div class="flex-center">
                    <el-avatar size="small" :src="po.head_img_url"></el-avatar>
                    {{ po.user_name }}
                  </div>
                </td>
                <td>{{ po.email }}</td>
                <td>{{ po.count }}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ContentWrapper>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import { getLoadingInstance, showErrorMessage } from '@/helpers';
import RecevierDistrictsChart from '@/components/Charts/RecevierDistrictsChart';
import PostOfficeOrdersChart from '@/components/Charts/PostOfficeOrdersChart';
import UsersDailyChart from '@/components/Charts/UsersDailyChart';
import OrdersDailyChart from '@/components/Charts/OrdersDailyChart';
import PostOfficeOrdersDailyChart from '@/components/Charts/PostOfficeOrdersDailyChart';

/* eslint-disable */

export default {
  name: 'Dashboard',
  data() {
    return {
      interval: null,
      realTimeStatistics: null,
      dailyStatistics: null,
      dailyStatisticsDates: [
        moment().subtract(8, 'd').format('YYYY-MM-DD'),
        moment().subtract(1, 'd').format('YYYY-MM-DD'),
      ],
    };
  },
  computed: {
    ...mapState({
      parcelModels: state => state.parcel.models,
    }),
    ...mapGetters(['currentUser']),
    // 門店寄件郵包模型
    parcelModelsType1() {
      return _.filter(this.parcelModels, pm => pm.type === 1);
    },
  },
  mounted() {
    this.$store.dispatch('fetchParcelModels');
    this.getRealTimeStatistics();
    this.getDailyStatistics();
    this.interval = setInterval(() => {
      this.getRealTimeStatistics();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getRealTimeStatistics() {
      let loading;
      if (!this.realTimeStatistics) {
        loading = getLoadingInstance();
      }
      this.$api.statistics.realTime().then(({ data: response }) => {
        this.realTimeStatistics = response;
      }).catch((e) => {
        showErrorMessage(e);
      }).finally(() => {
        if (loading) {
          loading.close();
        }
      });
    },
    getDailyStatistics() {
      const params = {
        start: this.dailyStatisticsDates[0],
        end: this.dailyStatisticsDates[1],
      };
      this.$api.statistics.daily(params).then(({ data: response }) => {
        this.dailyStatistics = response;
      }).catch((e) => {
        showErrorMessage(e);
        this.dailyStatistics = null;
      });
    },
    hasPostOfficePermission(post_office_id) {
      if (!this.currentUser) {
        return false;
      }
      if (this.currentUser.all_privileges) {
        return true;
      }
      const po = _.find(this.currentUser.post_offices, p => p.id === post_office_id);
      return Boolean(po);
    },
  },
  components: {
    RecevierDistrictsChart,
    PostOfficeOrdersChart,
    UsersDailyChart,
    OrdersDailyChart,
    PostOfficeOrdersDailyChart,
  },
};
</script>

<style lang="scss">
  @import "../styles/app/common/variables";
  .statistics-row {
    a {
      color: white;
    }
  }
  .post-office-order-card {
    &.br {
      &:last-child {

      }
    }
  }

  .statistics-table {
    &.table-hover tbody tr {
      &:hover {
        color: white;
        background-color: $inverse;
      }
    }
  }

</style>
