<template>
  <div style="height: 400px" ref="userDailyChart" id="user-daily-chart"></div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import {
  LineChart,
} from 'echarts/charts';
import {
  CanvasRenderer,
} from 'echarts/renderers';
import _ from 'lodash';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  LineChart,
  CanvasRenderer,
]);

export default {
  name: 'UsersDailyChart',
  mounted() {
    this.echartsInstance = echarts.init(this.$refs.userDailyChart);
    this.render();
  },
  props: {
    data: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      echartsInstance: null,
    };
  },
  watch: {
    data: {
      handler: 'render',
      deep: true,
    },
  },
  methods: {
    render() {
      const dates = _.map(this.data, 'date');
      const data = _.map(this.data, 'users');
      const option = {
        title: {
          text: '每日新增用戶統計',
          left: 'center',
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: dates,
        },
        yAxis: {
          type: 'value',
        },
        series: [{
          data,
          type: 'line',
        }],
      };
      this.echartsInstance.setOption(option, {
        notMerge: true,
      });
    },
  },
};
</script>
